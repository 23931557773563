import React, { useState } from "react"
import styled from "styled-components"
import { Container } from "../../../styles/global"
import DoownIcon from "./../../../images/pricing/downIcons.svg"
const Faq = () => {
  const [open, setOpen] = useState(1)
  const handleCollapse = (id) => {
    if (open === id) {
      setOpen(!id)
    } else {
      setOpen(id)
    }
  }
  return (
    <Wrapper>
      <SectionTitle>CAD Sharing FAQs</SectionTitle>
      <Container>
        <Main>
          {questions.map((li) => {
            const { id, question, answer } = li

            return (
              <FaqWrapper key={id}>
                <QuestionTab onClick={() => handleCollapse(id)}>
                  <Text>{question}</Text>

                  <IconWrapper>
                    <img
                      src={DoownIcon}
                      alt=""
                      className={open === id ? "open-icon" : "close-icon"}
                    />
                  </IconWrapper>
                </QuestionTab>
                <div className={`${open === id ? "open" : "collapse"} `}>
                  <Answer>{answer}</Answer>
                </div>
              </FaqWrapper>
            )
          })}
        </Main>
        <FooterWrappper>
          <FooterPara>Our support is always available at</FooterPara>
          <FooterEmail>hello@jinolo.com</FooterEmail>
        </FooterWrappper>
      </Container>
    </Wrapper>
  )
}

export default Faq
const Wrapper = styled.div`
  padding: 165px 0px 80px;
  background-color: #ffffff;
`
const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.background.navy};
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  text-align: center;
  color: #121640;
  padding-bottom: 80px;
`

const Main = styled.div`
  margin: 0 auto 32px;
`
const FaqWrapper = styled.div`
  margin: 0 auto 20px;
  padding: 10px 25px;
  background-color: #f5f5f5;
  border-radius: 10px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    padding: 10px 10px;
  }
`
const QuestionTab = styled.div`
  width: 100%;
  padding: 16px 0px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`

const Text = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 30px;
  text-align: center;
  color: #121640;
  transition: all 0.5 ease-in-out;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    font-size: 18px;
    line-height: 24px;
    text-align: left;
  }
`
const IconWrapper = styled.div`
  margin-right: 16px;
  display: flex;
  align-self: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    margin-right: 6px;
    

  }
`
const Answer = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #121640;
`
const FooterWrappper = styled.div`
  margin: 86px 0px 0px;
`
const FooterPara = styled.p`
  font-style: normal;
  font-weight: 200;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #121640;
`
const FooterEmail = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #121640;
`

const questions = [
  {
    id: 1,
    question: "What happens when I share a CAD file?",
    answer:
      "When you enter the Reviewer’s email address and click ‘Share’, we send an email to that person telling them that you shared a file with them. The recipient then clicks on the link in the email which takes them to the Jinolo website. They can only see the design once they sign in, making sure that your design is secure. ",
  },
  {
    id: 2,
    question: "Which file formats does Jinolo support?",
    answer: "Currently we support stl, 3dxml, and pdf file formats.  ",
  },
  {
    id: 3,
    question: "Can others download the file I share?",
    answer:
      "Only if you allow them to. When you share a design you can choose what the Reviewer can do with the document. If you select  the download option, a download button will appear in the Reviewer’s window and they can download the design. Otherwise they will only be allowed to view and/or comment on the file in the browser. ",
  },
  {
    id: 4,
    question: "Can I send files to people outside my team?",
    answer:
      "Yes. To share a file all you need is that person’s email address. They can be either a team member or an external Reviewer. ",
  },
]
