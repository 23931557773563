import React from "react"
import styled from "styled-components"
import { Container } from "../../../styles/global"
const ShareCad = () => {
  return (
    <Wrapper>
      <Container>
        <SectionTitle>How to share a CAD file with Jinolo</SectionTitle>
        <FeaturesGrid>
          <FeatureItem>
            <FeatureText>
              Start by uploading a 3D or 2D CAD file for your desktop.
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <FeatureText>
              Preview the file using our online CAD viewer and click ‘Share’.
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <FeatureText>
              Enter an email. They will get an email notification they have a
              file to view
            </FeatureText>
          </FeatureItem>
        </FeaturesGrid>

        {/*<Button href="https://calendly.com/jinolo/jinolo-onboarding" target="_blank">*/}
        {/*  start sharing now*/}
        {/*</Button>*/}
        <Button href="https://share.jinolo.com" target="_blank">
          Start Sharing Now
        </Button>
      </Container>
    </Wrapper>
  )
}

export default ShareCad
const Wrapper = styled.div`
  padding: 100px 0px;
  background-color: #99e7eb;
`
const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.background.navy};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
  max-width: 587px;
`
const FeaturesGrid = styled.div`
  max-width: 800px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureText = styled.p`
  color: ${(props) => props.theme.color.background.navy};
  text-align: justify;
  text-align: center;
  height: 6em;
`

const Button = styled.a`
  color: white;
  text-transform: uppercase;
  background-color: #121640;
  border-radius: 23px;
  display: flex;
  justify-content: center;
  max-width: 220px;
  margin: 22px auto 0px;
  padding: 14px 10px;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
`
