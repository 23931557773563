import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Hero from "../components/views/Product/hero"
import Banner from "../components/views/Product/banner"
import Faq from "../components/views/Product/faq"
import ShareCad from "../components/views/Product/shareCad"
import TransferCad from "../components/views/Product/transferCad"

const ProductPage = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Product" />
      <Hero />
      <Banner />
      <TransferCad />
      <ShareCad />
      <Faq />
    </Layout>
  )
}

export default ProductPage
