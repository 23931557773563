import React from "react"
import styled from "styled-components"
import { Container } from "../../../styles/global"
import bannerImage from "../../../images/product/bannerImg.png"
const Banner = () => {
  return (
    <Wrapper>
      <Container>
        <ImageWrapper>
          <Image src={bannerImage} alt="" />
        </ImageWrapper>
      </Container>
    </Wrapper>
  )
}

export default Banner
const Wrapper = styled.div`
  padding-top: 36px;
  padding-bottom: 54px;
  background-color: #121640;
`
const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const Image = styled.img`
  /* width: 100%; */
`
