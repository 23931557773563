import React from "react"
import styled from "styled-components"
import { Container } from "../../../styles/global"
import productPic1 from "../../../images/product/ProductGraphic1.png"
import productPic2 from "../../../images/product/ProductGraphic2.png"
import productPic3 from "../../../images/product/ProductGraphic3.png"
import productPic4 from "../../../images/product/ProductGraphic4.png"
const TransferCad = () => {
  return (
    <Wrapper>
      <Container>
        <SectionTitle>Everything you need to transfer CAD files</SectionTitle>
        <Flex>
          <Col>
            <ImageWrapper>
              <Image src={productPic1} alt="" />
            </ImageWrapper>
            <BlockTitle>Share directly in the browser</BlockTitle>
            <Paragraph>
              Easily share 3D designs in the browser. There is no need to send
              hundreds of emails or waste time dealing with attachments.
            </Paragraph>
          </Col>
          <Col>
            <ImageWrapper>
              <Image src={productPic2} alt="" />
            </ImageWrapper>
            <BlockTitle>Keep everything in one place</BlockTitle>
            <Paragraph>
              Jinolo keeps all the files you share and receive under your
              account. No more confusions over file versions.
            </Paragraph>
          </Col>
          <Col>
            <ImageWrapper>
              <Image src={productPic3} alt="" />
            </ImageWrapper>
            <BlockTitle>Collaborate with comments</BlockTitle>
            <Paragraph>
              Collaborate with everyone involved in the design process, with our
              instant comments. Come to decisions faster and keep a paper trail
              of discussions.
            </Paragraph>
          </Col>
          <Col>
            <ImageWrapper>
              <Image src={productPic4} alt="" />
            </ImageWrapper>
            <BlockTitle>Measure, annotate and inspect CAD</BlockTitle>
            <Paragraph>
              Inspect 3D CAD and 2D engineering drawings directly in the browser
              with annotations and measure tools. There is no need to install
              any extra software.
            </Paragraph>
          </Col>
        </Flex>
      </Container>
    </Wrapper>
  )
}

export default TransferCad
const Wrapper = styled.div`
  padding: 70px 10px;
  background-color: #ffffff;
`
const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.background.navy};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
  max-width: 587px;
`
const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 100px;
  grid-gap: 64px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`
const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`
const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const Image = styled.img`
  width: 100%;
  border-radius: 20px;
  margin-bottom: 30px;
`

const BlockTitle = styled.h3`
  color: ${(props) => props.theme.color.background.navy};
  margin-bottom: 20px;
  text-align: center;
  max-width: 447px;
  margin: auto;
  padding-bottom: 20px;
`
const Paragraph = styled.h2`
  font-style: normal;
  font-weight: 200;
  font-size: 22px;
  line-height: 30px;
  color: #121640;
  max-width: 447px;
  margin: auto;
  text-align: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    max-width: 700px;
  }
`
