import React from "react"
import styled from "styled-components"
import { Container } from "../../../styles/global"
const Hero = () => {
  return (
    <Wrapper>
      <Container>
        <SmallText>Jinolo for Design Sharing</SmallText>
        <Title>The simplest way to share your designs</Title>
        <Paragraph>
          Jinolo is a 3D and 2D CAD file sharing platform that helps connect
          engineers, suppliers, clients and other team members.
        </Paragraph>
        {/*<Button>Sign UP</Button>*/}
      </Container>
    </Wrapper>
  )
}

export default Hero
const Wrapper = styled.div`
  padding: 180px 0px 100px;
  background-color: #ffffff;
`
const SmallText = styled.h6`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #121640;
  padding-bottom: 10px;
`
const Title = styled.h2`
  font-style: normal;
  font-weight: bolder;
  font-size: 48px;
  line-height: 40px;
  text-align: center;
  color: #121640;
  max-width: 550px;
  margin: auto;
  padding-bottom: 35px;
`
const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #121640;
  max-width: 623px;
  margin: auto;
`
const Button = styled.a`
  color: white;
  text-transform: uppercase;
  background-color: #e43397;
  border-radius: 23px;
  display: flex;
  justify-content: center;
  max-width: 130px;
  margin: 27px auto 0px;
  padding: 14px 10px;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
`
